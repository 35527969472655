<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-form ref="bankAccountForm">
                <app-form-field
                        :class="'mt-2'"
                        v-model.trim="selectedBankCodeValue"
                        :items="banks"
                        :label="$t(`field.bank`)"
                        :hint="$t(`fieldHint.bank`)"
                        type="select"
                        :rules="this.selectedBankCodeValue == 0 ? [] : validator.dropDownListRules()"
                        :displayText="this.getBankDisplayText"
                ></app-form-field>
                <app-form-field
                        v-if="this.selectedBankCodeValue == '0'"
                        v-model.trim="bankName"
                        :label="$t(`field.bankName`)"
                        :hint="$t(`fieldHint.bankName`)"
                        :rules="validator.requiredRules(1, 50)"
                ></app-form-field>
                <app-form-field
                        v-if="showFullBankForm"
                        v-model.trim="bankAccountObj.memberBankAccountNumber"
                        :label="$t(`field.accountNumber`)"
                        :hint="$t(`fieldHint.accountNumber`)"
                        :rules="validator.bankAccountNumberRules(1, 20)"
                ></app-form-field>
                <app-form-field
                        v-model.trim="bankAccountObj.memberBankAccountName"
                        :label="$t(`field.accountName`)"
                        :hint="$t(`fieldHint.accountName`)"
                        :readonly="accountExist"
                        :rules="validator.requiredRules(1, 50)"
                ></app-form-field>
                <v-row no-gutters class="my-3" v-if="!isUpdateBank">
                    <v-col cols="12" align-self="center">
                        <v-checkbox class="mt-0 pt-0" v-model="bankAccountObj.saveNewBank"
                                    :label="$t(`label.saveBankAccountDetails`)" hide-details></v-checkbox>
                    </v-col>
                </v-row>

                <app-button :action="this.addBankAccount" :title="$t(`button.submit`)"></app-button>
                <app-button :action="this.closeNewBankAccountDialog" :title="$t(`button.cancel`)"
                            :customClass="'secondary_button buttonWhite'"></app-button>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
    import stingFormat from 'string-format'
    import {SESSION} from '@/constants/constants'
    import {formValidator, locale, uiHelper} from '@/util'

    export default {
        name: 'appAccount',
        props: {
            banks: {
                type: Array,
                required: true,
                default: () => []
            },
            showFullBankForm: {
                type: Boolean,
                default: false
            },
            closeNewBankAccountDialog: {
                type: Function,
                required: true,
                default: () => {
                }
            },
            bankAccountDetail: {
                type: Object,
                required: false,
                default: () => ({
                    memberBankAccountId: '',
                    memberBankAccountName: '',
                    memberBankAccountNumber: '',
                    memberBankName: '',
                    memberBankProvince: '',
                    memberBankBranch: '',
                    memberBankCity: '',
                    saveNewBank: false,
                    memberCode: localStorage.getItem(SESSION.USERNAME),
                    isUpdateBank: false
                })
            },
            isUpdateBank: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.currency = uiHelper.getCurrency()
            this.$refs.bankAccountForm.reset()
            /*if (this.bankAccountDetail != null) {
                this.accountExist = typeof this.bankAccountDetail != 'undefined' && this.bankAccountDetail.memberBankAccountName != null && this.bankAccountDetail.memberBankAccountName != '';
                this.bankAccountObj = this.bankAccountDetail
                if (this.$store.state.payment.banks.find(x => x.name == this.bankAccountDetail.memberBankName) != null)
                    this.selectedBankCodeValue = this.$store.state.payment.banks.find(x => x.name == this.bankAccountDetail.memberBankName).value
                else if (this.bankAccountDetail.memberBankAccountId != '') {
                    this.selectedBankCodeValue = 1
                    this.bankName = this.bankAccountDetail.memberBankName
                }
            }*/

            /* AUTO FILL UP MEMBER NAME IF FIELD IS EMPTY */
            if (this.bankAccountDetail == null || this.bankAccountDetail.memberBankAccountName == undefined || this.bankAccountDetail.memberBankAccountName == '') {
                this.bankAccountObj.memberBankAccountName = this.memberInfo.name
            }
        },
        computed: {
            memberInfo() {
                return this.$store.state.member.info
            }
        },
        data: () => ({
            accountExist: false,
            bankAccountObj: {
                memberBankAccountId: '',
                memberBankAccountName: '',
                memberBankAccountNumber: '',
                memberBankName: '',
                memberBankProvince: '',
                memberBankBranch: '',
                memberBankCity: '',
                saveNewBank: false,
                memberCode: localStorage.getItem(SESSION.USERNAME),
                isUpdateBank: false
            },
            validator: formValidator,
            stingFormat: stingFormat,
            showOtherBankNameField: false,
            selectedBankCodeValue: '',
            bankName: '',
            currency: ''
        }),
        methods: {
            getBankDisplayText(data) {
                if (data.value == 0) {
                    return locale.getMessage(`bankName.${data.displayText}`)
                } else {
                    return data.displayText
                }
            },
            addBankAccount() {
                if (this.$refs.bankAccountForm.validate()) {
                    if (this.selectedBankCodeValue != 0 && this.selectedBankCodeValue != '0') {
                        this.bankAccountObj.memberBankAccountId = this.bankAccountObj.memberBankAccountId != '' ? this.bankAccountObj.memberBankAccountId : '0'
                        this.bankAccountObj.memberBankName = this.banks.find(x => x.value == this.selectedBankCodeValue).name
                        this.bankAccountObj.bankCode = this.selectedBankCodeValue
                    } else {
                        //this.bankAccountObj.memberBankAccountId = this.selectedBankCodeValue.toString()
                        this.bankAccountObj.memberBankName = this.bankName
                    }
                    /* Force Save Bank if Compenent Open as Edit Bank */
                    if (this.isUpdateBank) this.bankAccountObj.saveNewBank = true
                    /* Update Value on Deposit or Withdrawal */
                    this.$emit('updateBankAccountObject', this.bankAccountObj)
                }
            }
        }
    }
</script>

<style lang="scss"></style>
