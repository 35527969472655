<template>
  <div>
    <v-card flat>
      <v-card-title>
        <label class="primary--text px-5 font-weight-bold bank-page-title ma-auto">{{
            $t(`label.${transactionObj.transactionType.toLowerCase()}`)
          }}</label>
      </v-card-title>
    </v-card>

    <v-card>
      <div v-if="!transactionResponseResult.complete">
        <!-- Payment Method -->
        <div v-if="availableMethods.length > 1">
          <v-card-title>
            <label class="ma-auto font-weight-bold subtitle-1 text-capitalize">{{
                $t(`label.chooseDepositMethod`)
              }}</label>
          </v-card-title>
          <div class="payment-method-list-container">
            <div class="payment-method-list text-center">
              <div class="payment-method-list-item" v-for="method in availableMethods" :key="method.code">
                <v-btn
                    @click="transactionObj.methodCode = method.code"
                    :class="transactionObj.methodCode == method.code ? 'active white--text' : 'secondary--text'"
                    height="auto"
                    min-height="auto"
                    class="text-center font-weight-bold my-0 pa-4 payment-method-list-item-button"
                >
                  <label>{{ $t(`paymentMethod.${method.code.toLowerCase()}`) }}</label>
                </v-btn>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="py-4 text-center">
          <div class="d-inline-block pa-2 body-2">
            <label>{{ `${$t(`label.affiliateCode`)}: ` }}</label>
            <label class="text-right font-weight-bold">{{ affiliateDetail.referralCode }}</label>
          </div>
          <div class="d-inline-block pa-2 px-1 body-2">
            <label>{{ `${$t(`label.affiliateBalance`)}: ` }}</label>
            <label class="text-right font-weight-bold">{{ affiliateDetail.walletBalance | currency }}</label>
          </div>
          <div class="d-inline-block pa-2 px-1 body-2">
            <label>{{ `${$t(`label.affiliateLevel`)}: ` }}</label>
            <label class="text-right font-weight-bold">{{
                $t(`affilateLevel.${affiliateDetail.affiliateTypeId}`)
              }}</label>
          </div>
        </div>
        <v-divider></v-divider>
        <v-stepper v-model="localBankWithdrawalStep" class="bank-transaction-stepper">
          <v-stepper-items>
            <v-stepper-content step="1" class="px-0">
              <v-form ref="withdrawalStepOneForm">
                <div class="bank-page-form">
                  <v-card-title>
                    <label class="ma-auto font-weight-bold subtitle-1 text-capitalize">{{
                        $t(`label.chooseWithdrawalAccount`)
                      }}</label>
                  </v-card-title>

                  <div class="pb-2">
                    <app-form-field
                        v-model.trim="transactionObj.memberBankAccountId"
                        :label="$t(`field.memberBank`)"
                        :hint="$t(`fieldHint.memberBank`)"
                        type="select"
                        :rules="validator.dropDownListRules()"
                        :items="memberBankAccounts"
                        :change="this.updateSelectedMemberBankAccount"
                        :displayText="this.memberBankAccountDisplayText"
                    ></app-form-field>
                  </div>
                  <div class="pb-2" v-if="transactionObj.memberBankAccountId">
                    <div>
                      <v-row no-gutters justify="center" class="pb-2">
                        <v-card class="bank-account-card">
                          <div class="pa-0 px-2 py-2">
                            <v-card-text class="pa-0 px-2 body-1 black--text font-weight-bold">
                              {{ transactionObj.memberBankName }}
                            </v-card-text>
                            <v-card-text class="pa-0 px-2 body-1 black--text font-weight-bold">
                              {{ transactionObj.memberBankAccountNumber }}
                            </v-card-text>
                            <v-card-text class="pa-0 px-2">{{ transactionObj.memberBankAccountName }}</v-card-text>
                          </div>
                        </v-card>
                      </v-row>
                    </div>
                  </div>
                  <div class="pb-2 text-center"
                       v-if="transactionObj.memberBankAccountId == '0' || transactionObj.memberBankAccountId == 0 || transactionObj.memberBankAccountId == undefined">
                    <v-btn text :ripple="false" class="text-uppercase font-weight-bold text-center secondary--text"
                           @click="openNewBankAccountDialog">
                      {{
                        $t(transactionObj.memberBankAccountId == '' ? `label.addBankAccount` : `label.changeBankAccount`)
                      }}
                    </v-btn>
                  </div>

                  <div class="pb-4">
                    <app-button :title="$t(`label.next`)"
                                :action="this.proceedToMemberWithdrawalDetailTab"></app-button>
                  </div>
                </div>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2" class="px-0">
              <v-form ref="transactionForm">
                <div class="bank-page-form">
                  <v-card-title>
                    <label class="ma-auto font-weight-bold subtitle-1 text-capitalize">{{
                        $t(`label.withdrawalDetails`)
                      }}</label>
                  </v-card-title>

                  <div class="pb-2">
                    <app-form-field
                        v-model="transactionObj.amount"
                        :change="this.updateMoneyAmount"
                        :label="$t(`field.withdrawalAmount`)"
                        :rules="validator.withdrawalAmountRules(2, selectedMethod != '' ? selectedMethod.minAmount : 100, selectedMethod != '' ? selectedMethod.maxAmount : 10000, transactionObj.currency)"
                        :hint="
                        stringFormat(
                          $t(`fieldHint.withdrawalAmount`),
                          selectedMethod != '' ? currencyFormatter(selectedMethod.minAmount) : currencyFormatter(100),
                          selectedMethod != '' ? currencyFormatter(selectedMethod.maxAmount) : currencyFormatter(10000)
                        )
                      "
                    ></app-form-field>
                  </div>
                  <div class="pb-4">
                    <app-button :title="$t(`label.submit`)" :action="this.submitTransaction"></app-button>
                    <app-button :title="$t(`label.back`)" :action="this.backToPreviousStepDetailTab"
                                :customClass="'secondary_button buttonWhite'"></app-button>
                  </div>
                </div>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
      <div v-else>
        <div class="bank-page-form">
          <v-card-title>
            <label v-if="transactionResponseResult.success"
                   class="ma-auto font-weight-bold subtitle-1 text-capitalize text-center">{{
                $t(`message.transactionSubmitted`)
              }}</label>
            <label v-else class="ma-auto font-weight-bold subtitle-1 text-capitalize">{{
                $t(`message.transactionRejected`)
              }}</label>
          </v-card-title>
          <v-card-title v-if="transactionResponseResult.success">
            <label class="ma-auto subtitle-1 d-block full-width text-center">{{
                $t(`message.transactionProcessing`)
              }}</label>
            <label class="ma-auto subtitle-1 d-block full-width text-center">{{
                $t(`message.receiveNotification`)
              }}</label>
          </v-card-title>
          <v-card-title v-else>
            <label v-if="transactionResponseResult.code != 0"
                   class="ma-auto subtitle-1 d-block full-width text-center red--text">
              {{ $t(`errorCodeDesc.${transactionResponseResult.code.toString().replace(/\./g, '_')}`) }}
            </label>
            <label v-else class="ma-auto subtitle-1 d-block full-width text-center">{{
                $t(`message.contactCs`)
              }}</label>
          </v-card-title>
          <app-button :title="$t(`label.makeAnotherTransaction`)" :action="this.makeNewTransaction"></app-button>
          <div v-if="transactionResponseResult.success">
            <app-button :action="this.viewTransactionHistory" :title="$t(`label.viewTransactionHistory`)"
                        :customClass="'secondary_button buttonWhite'"></app-button>
          </div>
        </div>
      </div>
    </v-card>

    <app-dialog :dialogShow="newBankAccountDialogShow" :max-width="600" :title="$t(`label.bankAccount`)"
                :closeAction="this.closeNewBankAccountDialog">
      <app-account
          :bankAccountDetail="bankAccountDetail"
          :showFullBankForm="true"
          :banks="banks"
          :closeNewBankAccountDialog="this.closeNewBankAccountDialog"
          @updateBankAccountObject="updateBankAccountData"
      ></app-account>
    </app-dialog>
  </div>
</template>

<script>
import {AFFILIATE_DETAIL} from '@/store/affiliate.module'
import currencyFormat from '@/filters/currency-format'
import {CACHE_KEY} from '@/constants/constants'
import {ROUTE_NAME} from '@/constants/route.constants'
import format from 'string-format'
import AppAccount from '@/components/bank/account.vue'
import {SHARED, SESSION} from '@/constants/constants'
import {formValidator, locale, uiHelper} from '@/util'
import {MEMBER_CHECK_TOKEN_VALIDITY, MEMBET_CHECK_WITHDRAW_STATUS} from '@/store/member.module'
import {
  AFFILIATE_PAYMENT_SUBMIT_PAYMENT_TRANSACTION,
  AFFILIATE_PAYMENT_AVAILABLE_METHOD,
  AFFILIATE_PAYMENT_RESET_TRANSACTION_RESPONSE_STORE
} from '@/store/affiliate.payment.module'

export default {
  name: 'withdrawal',
  metaInfo: {
    title: `${locale.getMessage('meta.withdrawal_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [
      {charset: 'utf-8'},
      {name: 'description', content: `${locale.getMessage('meta.withdrawal_description')}`},
      {name: 'keyword', content: `${locale.getMessage('meta.withdrawal_keyword')}`}
    ]
  },
  components: {
    AppAccount
  },
  data: () => ({
    currencyFormatter: currencyFormat,
    selectedMethod: '',
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    localBankWithdrawalStep: 1,
    stringFormat: format,
    validator: formValidator,
    newBankAccountDialogShow: false,
    transactionObj: {
      /* TRANSACTION INFO */
      transactionType: SHARED.WITHDRAWAL,
      methodCode: '',
      amount: 0,
      depositDateTime: '',
      attachmentFileType: '',
      attachment: '',
      ipAddress: '',
      saveNewBank: false,
      /* MEMBER INFO */
      memberCode: localStorage.getItem(SESSION.USERNAME),
      memberRealName: '',
      currency: localStorage.getItem(SESSION.CURRENCY),
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      memberEmail: '',
      memberContactIdorNumber: '',

      /* MERCHANT or ONLINE GATEWAY INFO */
      merchantBankCode: '',
      gatewayAccountId: '',
      gatewayAccountBankId: '',
      /* OTHER */
      transactionSuccessUrl: '',
      oriCurrency: '',
      oriAmount: '',
      language: uiHelper.getLanguage(),
      platform: uiHelper.getPlatform()
    },
    transactionResponseResult: {
      success: false,
      complete: false,
      code: 0
    },
    bankAccountDetail: {
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      saveNewBank: false,
      memberCode: localStorage.getItem(SESSION.USERNAME),
      isUpdateBank: false
    }
  }),
  computed: {
    banks() {
      return this.$store.state.affiliatePayment.banks
    },
    affiliateDetail() {
      return this.$store.state.affiliate.detail
    },
    availableMethods() {
      return this.$store.state.affiliatePayment.availableMethods.filter(x => x.transactionType.toLowerCase() == this.transactionObj.transactionType.toLowerCase())
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberBankAccounts() {
      return this.$store.state.affiliatePayment.memberBankAccounts
    },
    transactionResponseComplete() {
      return this.$store.state.affiliatePayment.trasnactionResponse.complete
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    withdrawStatus() {
      return this.$store.state.member.withdrawStatus
    }
  },
  watch: {
    memberBankAccounts() {
      this.preSelectMemberBankAccount()
    },
    availableMethods() {
      this.changeWithdrawalMethod(this.$store.state.affiliatePayment.availableMethods.filter(x => x.transactionType.toLowerCase() == this.transactionObj.transactionType.toLowerCase())[0])
    },
    transactionResponseComplete() {
      let transactionResponse = this.$store.state.affiliatePayment.trasnactionResponse

      if (transactionResponse.complete) {
        try {
          this.$ga.event(
              'Payment',
              `Affiliate Withdrawal`,
              `${this.transactionObj.methodCode.toUpperCase()} - ${transactionResponse.success ? 'Success' : 'Fail'}${!transactionResponse.success ? ` [Error : ${transactionResponse.code}]` : ''}`,
              transactionResponse.success ? this.transactionObj.amount : 0
          )
        } catch (err) {
          console.log(err)
        }
        this.$parent.$parent.getMemberWallet()
        this.transactionResponseResult = {
          success: transactionResponse.success,
          complete: transactionResponse.complete,
          code: transactionResponse.code
        }
        this.$store.dispatch(`${AFFILIATE_PAYMENT_RESET_TRANSACTION_RESPONSE_STORE}`)
      }
    },
    withdrawStatus() {
      let status = this.$store.state.member.withdrawStatus
      this.checkWithdrawStatus(status)
    }
  },
  created() {
    this.getAffiliateDetail()
    setInterval(this.getAffiliateDetail, 10000)
    this.checkMemberTokenValidity()
    this.getPaymentMethod()
    this.$parent.getMemberBankAccount()
  },
  methods: {
    preSelectMemberBankAccount() {
      if (this.memberBankAccounts.length > 0) {
        let memberBankAccounts = this.memberBankAccounts.find(x => x.id != '0')
        if (memberBankAccounts != null && memberBankAccounts != undefined) {
          this.transactionObj.memberBankAccountId = memberBankAccounts.id
          this.transactionObj.memberBankName = memberBankAccounts.bankName
          this.transactionObj.memberBankAccountNumber = memberBankAccounts.accountNumber
          this.transactionObj.memberBankAccountName = memberBankAccounts.accountName
        }
      }
    },
    getAffiliateDetail(requiredRefresh = false) {
      this.$store.dispatch(`${AFFILIATE_DETAIL}`, {requiredRefresh})
    },
    updateMoneyAmount(data) {
      this.transactionObj.amount = data.replace(/^0+/, '')
    },
    changeWithdrawalMethod(method) {
      this.selectedMethod = method
      this.transactionObj.methodCode = method.code

      if (method.code.toLowerCase() == 'lb') {
        this.localBankWithdrawalStep = 1
      }
    },
    getMemberWithdrawStatus() {
      this.$store.dispatch(MEMBET_CHECK_WITHDRAW_STATUS)
    },
    proceedToMemberWithdrawalDetailTab() {
      if (this.$refs.withdrawalStepOneForm.validate()) {
        this.localBankWithdrawalStep = 2
      }
    },
    backToPreviousStepDetailTab() {
      this.localBankWithdrawalStep = 1
    },
    updateBankAccountData(bankAccountObj) {
      if (bankAccountObj != null) {
        this.tempMemberBankAccountId = '0'
        this.transactionObj.memberBankAccountId = '0'
        this.transactionObj.memberBankAccountName = bankAccountObj.memberBankAccountName
        this.transactionObj.memberBankAccountNumber = bankAccountObj.memberBankAccountNumber
        this.transactionObj.memberBankName = bankAccountObj.memberBankName
        this.transactionObj.memberBankProvince = bankAccountObj.memberBankProvince
        this.transactionObj.memberBankBranch = bankAccountObj.memberBankBranch
        this.transactionObj.memberBankCity = bankAccountObj.memberBankCity
        this.transactionObj.saveNewBank = bankAccountObj.saveNewBank
      } else {
        this.transactionObj.memberBankAccountId = 0
        this.transactionObj.memberBankAccountName = ''
        this.transactionObj.memberBankAccountNumber = ''
        this.transactionObj.memberBankName = ''
        this.transactionObj.memberBankProvince = ''
        this.transactionObj.memberBankBranch = ''
        this.transactionObj.memberBankCity = ''
        this.transactionObj.saveNewBank = ''
      }

      this.closeNewBankAccountDialog()
    },
    makeNewTransaction() {
      this.localBankWithdrawalStep = 1
      this.transactionObj = {
        /* TRANSACTION INFO */
        transactionType: SHARED.WITHDRAWAL,
        methodCode: '',
        amount: 0,
        depositDateTime: '',
        attachmentFileType: '',
        attachment: '',
        ipAddress: '',
        saveNewBank: false,
        /* MEMBER INFO */
        memberCode: localStorage.getItem(SESSION.USERNAME),
        memberRealName: '',
        currency: localStorage.getItem(SESSION.CURRENCY),
        memberBankAccountId: '',
        memberBankAccountName: '',
        memberBankAccountNumber: '',
        memberBankName: '',
        memberBankProvince: '',
        memberBankBranch: '',
        memberBankCity: '',
        memberEmail: '',
        memberContactIdorNumber: '',

        /* MERCHANT or ONLINE GATEWAY INFO */
        merchantBankCode: '',
        gatewayAccountId: '',
        gatewayAccountBankId: '',
        /* OTHER */
        transactionSuccessUrl: uiHelper.getHostname(),
        oriCurrency: '',
        oriAmount: '',
        language: uiHelper.getLanguage(),
        platform: uiHelper.getPlatform()
      }

      this.transactionResponseResult = {
        success: false,
        complete: false,
        code: 0
      }
      this.$parent.getMemberBankAccount()
    },
    checkMemberTokenValidity() {
      let requiredLoading = false
      this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, {requiredLoading})
    },
    getPaymentMethod() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME),
        realName: this.memberInfo.name,
        currency: localStorage.getItem(SESSION.CURRENCY)
      }
      this.$store.dispatch(`${AFFILIATE_PAYMENT_AVAILABLE_METHOD}`, {obj})
    },

    updateSelectedMemberBankAccount(data) {
      if (data != 0) {
        this.tempMemberBankAccountId = data
        let selectedMemberAccount = this.memberBankAccounts.find(x => x.id == data)
        this.transactionObj.memberBankAccountName = selectedMemberAccount.accountName
        this.transactionObj.memberBankAccountNumber = selectedMemberAccount.accountNumber
        this.transactionObj.memberBankName = selectedMemberAccount.bankName
      } else if (data == 0) {
        this.openNewBankAccountDialog(false)
      }
    },
    submitTransaction() {
      if (this.$refs.transactionForm.validate()) {
        let obj = this.transactionObj
        obj.memberRealName = this.memberInfo.name
        this.$store.dispatch(`${AFFILIATE_PAYMENT_SUBMIT_PAYMENT_TRANSACTION}`, {obj})
      }
    },
    openNewBankAccountDialog(otherBank = true) {
      if (this.transactionObj.memberBankAccountId == 0 && otherBank) {
        this.bankAccountDetail = {
          memberBankAccountId: this.transactionObj.memberBankAccountId,
          memberBankAccountName: this.transactionObj.memberBankAccountName,
          memberBankAccountNumber: this.transactionObj.memberBankAccountNumber,
          memberBankName: this.transactionObj.memberBankName,
          memberBankProvince: this.transactionObj.memberBankProvince,
          memberBankBranch: this.transactionObj.memberBankBranch,
          memberBankCity: this.transactionObj.memberBankCity,
          saveNewBank: this.transactionObj.saveNewBank,
          memberCode: this.transactionObj.memberCode,
          isUpdateBank: false
        }
      } else {
        this.bankAccountDetail = null
      }
      this.newBankAccountDialogShow = true
    },
    closeNewBankAccountDialog() {
      this.transactionObj.memberBankAccountId = this.tempMemberBankAccountId
      this.newBankAccountDialogShow = false
    },
    memberBankAccountDisplayText(data) {
      if (data.value == 0) {
        return locale.getMessage(`label.${data.displayText}`)
      } else {
        return data.displayText
      }
    },
    checkWithdrawStatus(status) {
      if (!status.emailVerified || !status.mobileVerified || !status.profileComplete) {
        let dialog = this.$parent.$parent.initializePageDialogProperty()
        dialog.dialogXButton = null
        dialog.title = locale.getMessage(`label.incompleteProfile`)
        dialog.message.push(locale.getMessage(`label.incompleteProfileDesc`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: () => {
            this.$parent.$parent.closePageDialog()
            this.$router.push({
              name: ROUTE_NAME.PROFILE
            })
          }
        })
        this.$parent.$parent.openPageDialog(dialog)
      } else if (!status.turnoverHit) {
        let dialog = this.$parent.$parent.initializePageDialogProperty()
        dialog.dialogXButton = null
        dialog.title = locale.getMessage(`label.turnoverNotMet`)
        dialog.message.push(locale.getMessage(`label.turnoverNotMetDesc`))

        this.storageGameProviderType.forEach(type => {
          let route = ROUTE_NAME.SLOT
          let buttonTitle = locale.getMessage(`label.${type.type}_short`)
          switch (type.type) {
            case 'sb':
              route == ROUTE_NAME.SPORTSBOOK
              break
            case 'ld':
              route == ROUTE_NAME.CASINO
              break
            case 'rng':
              route == ROUTE_NAME.SLOT
              break
            case 'fishing':
              route == ROUTE_NAME.FISHING
              break
          }
          dialog.button.push({
            title: buttonTitle,
            action: () => {
              this.$parent.$parent.closePageDialog()
              this.$router.push({
                name: route
              })
            }
          })
        })

        this.$parent.$parent.openPageDialog(dialog)
      }
    },
    viewTransactionHistory() {
      this.$router.push({
        name: ROUTE_NAME.AFFILIATE_TRANSACTION,
        params: {
          tab: 'withdrawal'
        }
      })
    }
  }
}
</script>
